<template>
  <div class="container">
    <div class="h-100 row">
      <div class="mx-auto my-auto col-12 col-md-10 bg-white rounded-lg">
        <div class="row">
          <div class="col-md-5 p-0">
            <div class="image-side">
              <h1 class="font-weight-light text-white">CABRERA IS IN THE DETAILS</h1>
              <p class="text-white mb-0">
                They have sent you an invitation.
                If you are not a member, please register.
              </p>
            </div>
          </div>
          <div class="col-md-7 py-10 px-11" v-loading="loading">
            <h2 class="display-4 mb-4">Please confirm your data</h2>
            <el-form
              :model="form"
              ref="form"
              :hide-required-asterisk="true"
              :rules="rules"
              @submit.native.prevent
            >

              <el-form-item
                :label="$t('office.name')"
                prop="name"
                :rules="[{ required: true, message: 'The field is required'}]"
              >
              <el-input type="text" v-model="form.name"></el-input>
              </el-form-item>

              <el-form-item
                :label="$t('office.phone')"
                prop="phone"
                :rules="[{ required: true, message: 'The field is required'}]"
              >
                <el-input type="text" v-model="form.phone"></el-input>
              </el-form-item>
              
              <el-form-item
                :label="$t('register.password')"
                prop="password"
                :rules="[{ required: true, message: 'The field is required'}]"
              >
                <el-input type="password" v-model="form.password" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item :label="$t('register.confirm')" prop="checkPass">
                <el-input type="password" v-model="form.checkPass" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item class="mb-0">

                <el-button
                  class="btn-big"
                  type="primary"
                  @click="submitForm('form')"
                >{{$t('register.register')}}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/services/api/auth";

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === null) {
        callback(new Error("Please input the password again"));
      } else if (value !== this.form.password) {
        callback(new Error("Two inputs don't match!"));
      } else {
        callback();
      }
    };
    return {
      loading: true,
      form: {
        name: null,
        phone: null,
        password: null,
        checkPass: null,
        token: this.$route.params.token
      },
      rules: {
        checkPass: [{ validator: validatePass }]
      }
    };
  },
  mounted() {
    auth
      .check(this.$route.params.token)
      .then(response => {
        this.form.name = response.name;
        this.form.phone = response.phone;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          auth
            .activate(this.form)
            .then(() => {
              this.$message({
                message: "Congrats, this is a success message.",
                type: "success",
                customClass: "message-success"
              });
              this.$router.push("/");
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss">
</style>
